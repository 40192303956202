<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-img :src="require('@/assets/images/pages/pelopor_keselamatan.png')" class="congratulations-img-left" fluid />

        <b-card class="card-congratulations mt-1  bg-info  match-height" @click="act_data('')">
          <b-row>
            <b-col md="12">
              <h5 class="text-center"> <span class="text-white"> Pendaftaran Pelopor Keselamatan</span>
              </h5>
              <small>Kategori</small>
              <h1 class="text-warning">PELAJAR</h1>
              <h6 class="text-white"><small>Tanggal pendaftaran </small>
                <br> <span class="text-white">01 Januari 2024 </span> </h6>
              <h6><small>Sampai Dengan</small><br> <span class="text-warning">30 januari 2024 </span></h6>
              <b-button block variant="outline-warning" size="sm" v-b-modal.modal-2>
                <feather-icon icon="ListIcon" size="15"></feather-icon> Detail Informasi
              </b-button>
            </b-col>

          </b-row>
        </b-card>
      </b-col>
      <b-col md="8">
        <b-card>
          <detail_pendaftaran></detail_pendaftaran>
        </b-card>
        <b-card>
          <Profil_pendaftar></Profil_pendaftar>
        </b-card>
      </b-col>
    </b-row>
    <ajukan_pendaftaran></ajukan_pendaftaran>
    <info></info>
  </div>
</template>

<script>
  import {
    BRow,
    BCol,
    BCard,
    BAvatar,
    BButton,
    BImg,
  } from 'bootstrap-vue'
  import ajukan_pendaftaran from './component/ajukan_pendaftaran.vue'
  import Profil_pendaftar from './component/profil_pendaftar.vue';
  import detail_pendaftaran from './component/detail_pendaftaran.vue';
  import Info from './component/info.vue';
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BAvatar,
      BAvatar,
      BButton,
      BImg,
      Profil_pendaftar,
      detail_pendaftaran,
      ajukan_pendaftaran,
      Info
    }
  }
</script>

<style>

</style>