<template>
    <div>
        <b-row>
            <b-col md="4" xs="12">
                <b-col>
                    <b-img :src="require('@/assets/images/pages/pelajar.jpg')" class="congratulations-img-left"
                        thumbnail fluid />
                </b-col>
            </b-col>
            <b-col md="8" xs="12">
                <small>Nama</small>
                <h5 class="text-warning">ZULFA KAMILA AZZAHRA</h5>
                <h6><small>tempat, Tanggal Lahir </small>
                    <br> <span class="text-primary">Boyolali, 19 November 2005 </span> </h6>
                <h6><small>Jenis Kelamin</small> <span class="text-warning">Laki-laki </span></h6>
                <h6><small>Ukuran Kaos</small>
                    "L"
                </h6>
                <a href="https://wa.me/+6282146731872" target="_blank">
                    <h6><small>No HP</small></h6>
                    <h6>
                        <feather-icon icon="PhoneCallIcon" size="15"></feather-icon> +6282146731872
                    </h6>
                </a>
                <h6><small>Email</small></h6>
                <h6>
                    nurulhanum25@gmail.com
                </h6>
                <h6><small>Alamat</small></h6>
                <p><small> Blitar RT01/RW03, Kec. Madukara, Kab. Banjarnegara,</small></p>
            </b-col>
        </b-row>

        <b-row>

            <b-col md="6">

            </b-col>
        </b-row>
        <hr>
        <b-row>
            <b-col md="6">
                <b-row>
                    <b-col md="12">
                        <h6><small>Instansi Pengirim</small></h6>
                        <h6>Pendaftaran Pelopor Keselamatan Tahun 2022</h6>
                    </b-col>
                </b-row>
                <h6><small>SMAN 1 DEMAK , Kelas XI (11)</small></h6>
                <h6><small>Jurusan</small></h6>
                <h6>Ilmu Terapan, Ilmu Formal, Kesehatan, Ilmu Alam</h6>

            </b-col>
            <b-col md="6">
                <h6><small>Pengalaman Organisasi</small></h6>

                <li>1. OSIS SMP (menjabat sebagai Ketua OSIS periode 2019/2020).</li>
                <li>2. Dewan Penggalang SMP (menjabat sebagai Pratama Putri periode 2019/2020).</li>
                <li>3. Bantara - Laksana Pramuka penegak ambalan Ki Hajar Dewantara - R.A. Kartini SMAN 3
                    Pekalongan.</li>
                <li>4. Organisasi PKS - Paskibra (PASTIGA) di SMA</li>
                <li>5. Sinang - Sinok Duta SMAN 3 Pekalongan</li>
                <li>6. SMAGATV Crew (as host podcast)</li>
                <li>7. Panitia event : MPLS Smaga 2022, Penerimaan Ambalan 2022, Pelantikan Bantara Laksana
                    2023, dll."</li>


            </b-col>
        </b-row>
        <hr>
        <b-row>
            <b-col md="12">
                <h5>Karya Tulis Ilmiah</h5>
            </b-col>
            <b-col md="6">
                <h6><small>Judul Karya Tulis</small></h6>
                <h6>STEP-UP BOOSTER ARUS DC UNTUK MENSTABILKAN TEGANGAN BATERAI PADA KENDARAAN AGAR TENAGA PADA
                    KENDARAAN LEBIH RESPONSIF DAN BAHAN BAKAR LEBIH IRIT</h6>
            </b-col>
            <b-col md="6">
                <b-button block variant="warning" size="sm">
                    <feather-icon icon='Link2Icon' size='15'></feather-icon> karya Tulis Ilmiah
                </b-button>
                <b-button block variant="warning" size="sm">
                    <feather-icon icon='Link2Icon' size='15'></feather-icon> Paparan karya Tulis
                </b-button>
                <b-button block variant="warning" size="sm">
                    <feather-icon icon='Link2Icon' size='15'></feather-icon> Foto
                </b-button>
                <b-button block variant="warning" size="sm">
                    <feather-icon icon='Link2Icon' size='15'></feather-icon> Link Youtube
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {
        BRow,
        BCol,
        BCard,
        BAvatar,
        BButton,
        BImg,
    } from 'bootstrap-vue'
    export default {
        components: {
            BRow,
            BCol,
            BCard,
            BAvatar,
            BAvatar,
            BButton,
            BImg

        }
    }
</script>

<style>

</style>