<template>
  <div>
    <!-- basic modal -->
    <b-modal id="modal-1" title="Pendaftaran Pelajar Pelopor Keselamatan" ok-only ok-title="Accept" hide-footer
      no-close-on-backdrop size="xl">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>


            <b-col md="6">
              <b-alert variant="primary" show>
                <div class="alert-body">
                  <b-row>
                    <b-col md="12">
                      <h4 class="text-primary">
                        <feather-icon icon="ListIcon" size="15"></feather-icon> Data Diri
                      </h4>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-form-group label="Nama Lengkap">
                        <validation-provider #default="{ errors }" name="First Name" rules="required">
                          <b-form-input v-model="name" :state="errors.length > 0 ? false:null"
                            placeholder="First Name" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <h6>Tempat Tanggal Lahir</h6>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="4">
                      <b-form-group label="  ">
                        <validation-provider #default="{ errors }" name="First Name" rules="required">
                          <b-form-input v-model="name" :state="errors.length > 0 ? false:null"
                            placeholder="Tempat Lahir" />
                          <small><i>Tempat lahir</i></small>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="8">
                      <b-row>
                        <b-col md="4">
                          <b-form-group label=" ">
                            <validation-provider #default="{ errors }" name="First Name" rules="required">
                              <b-form-select aria-placeholder="Bulan" v-model="selected" :options="bulan" />
                              <small><i>Tanggal</i></small>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group label=" ">
                            <validation-provider #default="{ errors }" name="First Name" rules="required">
                              <b-form-select aria-placeholder="Bulan" v-model="selected" :options="bulan" />
                              <small><i>Bulan</i></small>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group label=" ">
                            <validation-provider #default="{ errors }" name="First Name" rules="required">
                              <b-form-input v-model="name" :state="errors.length > 0 ? false:null" placeholder="2000"
                                type="number" max="5" />
                              <small><i>Tahun</i></small>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group label="Jenis Kelamin">

                        <b-row>
                          <b-col md="6">
                            <b-form-radio v-model="Selected" name="some-radios" value="A">
                              Laki-Laki
                            </b-form-radio>
                          </b-col>
                          <b-col md="6">
                            <b-form-radio v-model="Selected" name="some-radios" value="A">
                              Perempuan
                            </b-form-radio>
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </b-col>


                  </b-row>
                  <b-row>

                    <b-col md="6">
                      <h6>Foto Profil <small>(* Past Foto ukuran 4x6)</small></h6>
                      <b-form-group label="">
                        <validation-provider #default="{ errors }" name="First Name" rules="required">
                          <b-form-file v-model="file" :state="errors.length > 0 ? false:null"
                            placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>


                    <b-col md="6">
                      <b-form-group label="Ukuran Kaos">
                        <validation-provider #default="{ errors }" name="First Name" rules="required">
                          <b-form-select aria-placeholder="Bulan" v-model="selected" :options="bulan" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group label="Ho HP">
                        <validation-provider #default="{ errors }" name="No HP" rules="required">
                          <b-form-input v-model="name" :state="errors.length > 0 ? false:null"
                            placeholder="First Name" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Email">
                        <validation-provider #default="{ errors }" name="No HP" rules="required">
                          <b-form-input v-model="name" :state="errors.length > 0 ? false:null"
                            placeholder="First Name" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-form-group label="Alamat">
                        <validation-provider #default="{ errors }" name="No HP" rules="required">
                          <b-form-textarea id="textarea-default" placeholder="Textarea" rows="3"
                            :state="errors.length > 0 ? false:null" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group label="Asal Sekolah">
                        <validation-provider #default="{ errors }" name="First Name" rules="required">
                          <b-form-input v-model="name" :state="errors.length > 0 ? false:null"
                            placeholder="First Name" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Kelas">
                        <validation-provider #default="{ errors }" name="First Name" rules="required">
                          <b-form-input v-model="name" :state="errors.length > 0 ? false:null"
                            placeholder="First Name" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group label="jurusan">
                        <validation-provider #default="{ errors }" name="First Name" rules="required">
                          <b-form-input v-model="name" :state="errors.length > 0 ? false:null"
                            placeholder="First Name" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                </div>
              </b-alert>
            </b-col>





            <b-col md="6">
              <b-alert variant="warning" show>
                <div class="alert-body">
                  <b-row>
                    <b-col md="12">
                      <h4 class="text-primary">
                        <feather-icon icon="ListIcon" size="15"></feather-icon> Profil Calon Pelopor Keselamatan
                      </h4>
                      <b-row>
                        <b-col md="12">
                          <b-form-group label="Instansi Pengirim">
                            <validation-provider #default="{ errors }" name="First Name" rules="required">
                              <b-form-input v-model="name" :state="errors.length > 0 ? false:null"
                                placeholder="First Name" />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <b-form-group label="Pengaaman Organisasi">
                            <validation-provider #default="{ errors }" name="No HP" rules="required">
                              <b-form-textarea id="textarea-default" placeholder="Textarea" rows="3"
                                :state="errors.length > 0 ? false:null" />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <h5>Karya Tulis Ilmiah</h5>
                        </b-col>
                        <b-col md="12">
                          <b-form-group label="Judul ">
                            <validation-provider #default="{ errors }" name="No HP" rules="required">
                              <b-form-textarea id="textarea-default" placeholder="Textarea" rows="2"
                                :state="errors.length > 0 ? false:null" />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group label="Link Youtube">
                            <validation-provider #default="{ errors }" name="First Name" rules="required">
                              <b-form-input v-model="name" :state="errors.length > 0 ? false:null"
                                placeholder="First Name" />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>

                          <h6>File Karya Tulis <small>(* File type PDF max 2Mb)</small></h6>
                          <b-form-group label="">
                            <validation-provider #default="{ errors }" name="First Name" rules="required">
                              <b-form-file v-model="file" :state="errors.length > 0 ? false:null"
                                placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                          <h6>Paparan Karya Tulis <small>(* File type PDF max 2Mb)</small></h6>
                          <b-form-group label="">
                            <validation-provider #default="{ errors }" name="First Name" rules="required">
                              <b-form-file v-model="file" :state="errors.length > 0 ? false:null"
                                placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </b-alert>
            </b-col>
          </b-row>
          <hr>
          <b-row>

            <b-col cols="12">
              <b-button variant="primary" block type="submit" @click.prevent="validationForm">
                <feather-icon icon="UploadCloudIcon" size="15"></feather-icon>  Ajukan
              </b-button>
              <b-button variant="warning" block type="submit" @click.prevent="validationForm">
                <feather-icon icon="XCircleIcon" size="15"></feather-icon>  Batal Ajukan
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
  import {
    ValidationProvider,
    ValidationObserver
  } from 'vee-validate'
  import {
    required,
    email
  } from '@validations'

  import {
    BModal,
    BButton,
    VBModal,
    BAlert,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormSelect,
    BFormRadio,
    BFormTextarea,
    BFormFile
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      BButton,
      BModal,
      BAlert,
      BFormInput,
      BFormGroup,
      BForm,
      BRow,
      BCol,
      BFormSelect,
      BFormRadio,
      BFormTextarea,
      BFormFile,
    },
    data() {
      return {
        emailValue: '',
        name: '',
        required,
        email,
        bulan: [{
          value: '1',
          text: '01'
        }, ]
      }
    },
    methods: {
      validationForm() {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            // eslint-disable-next-line
            alert('form submitted!')
          }
        })
      },
    },
    directives: {
      'b-modal': VBModal,
      Ripple,
    },
  }
</script>