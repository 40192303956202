<template>
    <div> 
      <!-- basic modal -->
      <b-modal id="modal-2"  ok-only ok-title="Accept" hide-footer size="lg">
        <b-row>
            <b-col md="12">
                <h5 class="text-primary">Pendaftaran Pelopor Keselamatan Tahun 2022</h5>
                <h6>Ketentuan</h6>
                <p style="text-align :justify"><small>Tootsie roll lollipop lollipop icing. Wafer cookie
                        danish macaroon. Liquorice
                        fruitcake apple pie I love cupcake cupcake. I love cupcake cupcake. I love cupcake
                        cupcake. I love cupcake cupcake. I love cupcake cupcake. I love cupcake
                        cupcake.</small></p>
                <b-row>
                    <b-col md="4">
                        <small>Kategori</small>
                        <h6 class="text-warning">PELAJAR</h6>
                    </b-col>
                    <b-col md="4">
                        <h6><small>Tanggal pendaftaran </small>
                            <br> <span class="text-primary">01 Januari 2024 </span> </h6>
                    </b-col>
                    <b-col md="4">
                        <h6><small>Sampai Dengan</small><br> <span class="text-warning">30 januari 2024 </span></h6>
                    </b-col>
                </b-row>  
            </b-col>
        </b-row>
      </b-modal> 
    </div>
  </template>
  
  <script>
    import {
      BModal,
      BButton,
      VBModal,
      BAlert, BRow, BCol
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
  
    export default {
      components: {
        BButton,
        BModal,
        BAlert, BRow, BCol
      },
      directives: {
        'b-modal': VBModal,
        Ripple,
      },
    }
  </script>